<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Create Helicopter Details
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.heliModel"
                            :error-messages="heliModelErrors"
                            label="Heli Model"
                            required
                            @input="$v.editedData.heliModel.$touch()"
                            @blur="$v.editedData.heliModel.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.seatNumber"
                            :error-messages="seatNumberErrors"
                            label="Seat Number"
                            required
                            @input="$v.editedData.seatNumber.$touch()"
                            @blur="$v.editedData.seatNumber.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.flightNumber"
                            :error-messages="flightNumberErrors"
                            label="Flight Number"
                            required
                            @input="$v.editedData.flightNumber.$touch()"
                            @blur="$v.editedData.flightNumber.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.heliAge"
                            :error-messages="heliAgeErrors"
                            label="Heli Age"
                            required
                            @input="$v.editedData.heliAge.$touch()"
                            @blur="$v.editedData.heliAge.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.maximumTakeoffweight"
                            :error-messages="maximumTakeoffweightErrors"
                            label="Maximum Takeoff Weight"
                            required
                            @input="$v.editedData.maximumTakeoffweight.$touch()"
                            @blur="$v.editedData.maximumTakeoffweight.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.maximumOperatingAltitude"
                            :error-messages="maximumOperatingAltitudeErrors"
                            label="Maximum Operating Altitude"
                            required
                            @input="$v.editedData.maximumOperatingAltitude.$touch()"
                            @blur="$v.editedData.maximumOperatingAltitude.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success", "serviceID"],
        computed: {
            heliModelErrors() {
                const errors = [];
                if (!this.$v.editedData.heliModel.$dirty) return errors;
                !this.$v.editedData.heliModel.required &&
                errors.push("Sector Type is required");
                return errors;
            },
            seatNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.seatNumber.$dirty) return errors;
                !this.$v.editedData.seatNumber.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            flightNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.flightNumber.$dirty) return errors;
                !this.$v.editedData.flightNumber.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            heliAgeErrors() {
                const errors = [];
                if (!this.$v.editedData.heliAge.$dirty) return errors;
                !this.$v.editedData.heliAge.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            maximumTakeoffweightErrors() {
                const errors = [];
                if (!this.$v.editedData.maximumTakeoffweight.$dirty) return errors;
                !this.$v.editedData.maximumTakeoffweight.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            maximumOperatingAltitudeErrors() {
                const errors = [];
                if (!this.$v.editedData.maximumOperatingAltitude.$dirty) return errors;
                !this.$v.editedData.maximumOperatingAltitude.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                province: [],
                show: false,
                updated: false,
                editedData: {
                    heliModel: "",
                    seatNumber: "",
                    flightNumber: "",
                    heliAge: "",
                    maximumTakeoffweight: "",
                    maximumOperatingAltitude: ""
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                heliModel: {required},
                seatNumber: {required},
                flightNumber: {required},
                heliAge: {required},
                maximumTakeoffweight: {required},
                maximumOperatingAltitude: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
                let serviceData = await axios.get(`HeliCopter/GetHeliCopterDetailByIDAsync/${this.serviceID}`);
                this.editedData = serviceData.data
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        HeliModel: this.editedData.heliModel,
                        FlightNumber: this.editedData.flightNumber,
                        SeatNumber: parseInt(this.editedData.seatNumber),
                        HeliAge: parseInt(this.editedData.heliAge),
                        MaximumTakeoffweight: parseInt(this.editedData.maximumTakeoffweight),
                        MaximumOperatingAltitude: parseInt(this.editedData.maximumOperatingAltitude)
                    };
                    axios.post("HeliCopter/InsertIntoHeliCopterDetailAsync", param).then(response => {
                        if (response.status == 200) {
                            this.$v.$reset();
                            this.servicesData.data = response.data
                            this.servicesData.message = "Details added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.heliModel = "";
                this.editedData.seatNumber = "";
            }
        }
    };
</script>

<style scoped>
</style>