<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Create Helicopter Details
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.HeliModel"
                            :error-messages="HeliModelErrors"
                            label="Heli Model"
                            required
                            @input="$v.editedData.HeliModel.$touch()"
                            @blur="$v.editedData.HeliModel.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.SeatNumber"
                            :error-messages="SeatNumberErrors"
                            label="Seat Number"
                            required
                            @input="$v.editedData.SeatNumber.$touch()"
                            @blur="$v.editedData.SeatNumber.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.FlightNumber"
                            :error-messages="FlightNumberErrors"
                            label="Flight Number"
                            required
                            @input="$v.editedData.FlightNumber.$touch()"
                            @blur="$v.editedData.FlightNumber.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.HeliAge"
                            :error-messages="HeliAgeErrors"
                            label="Heli Age"
                            required
                            @input="$v.editedData.HeliAge.$touch()"
                            @blur="$v.editedData.HeliAge.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.MaximumTakeoffweight"
                            :error-messages="MaximumTakeoffweightErrors"
                            label="Maximum Takeoff Weight"
                            required
                            @input="$v.editedData.MaximumTakeoffweight.$touch()"
                            @blur="$v.editedData.MaximumTakeoffweight.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.MaximumOperatingAltitude"
                            :error-messages="MaximumOperatingAltitudeErrors"
                            label="Maximum Operating Altitude"
                            required
                            @input="$v.editedData.MaximumOperatingAltitude.$touch()"
                            @blur="$v.editedData.MaximumOperatingAltitude.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            HeliModelErrors() {
                const errors = [];
                if (!this.$v.editedData.HeliModel.$dirty) return errors;
                !this.$v.editedData.HeliModel.required &&
                errors.push("Sector Type is required");
                return errors;
            },
            SeatNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.SeatNumber.$dirty) return errors;
                !this.$v.editedData.SeatNumber.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            FlightNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.FlightNumber.$dirty) return errors;
                !this.$v.editedData.FlightNumber.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            HeliAgeErrors() {
                const errors = [];
                if (!this.$v.editedData.HeliAge.$dirty) return errors;
                !this.$v.editedData.HeliAge.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            MaximumTakeoffweightErrors() {
                const errors = [];
                if (!this.$v.editedData.MaximumTakeoffweight.$dirty) return errors;
                !this.$v.editedData.MaximumTakeoffweight.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
            MaximumOperatingAltitudeErrors() {
                const errors = [];
                if (!this.$v.editedData.MaximumOperatingAltitude.$dirty) return errors;
                !this.$v.editedData.MaximumOperatingAltitude.required &&
                errors.push("Sector Code is required.");
                return errors;
            },
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                province: [],
                show: false,
                updated: false,
                editedData: {
                    HeliModel: "",
                    SeatNumber: "",
                    FlightNumber: "",
                    HeliAge: "",
                    MaximumTakeoffweight: "",
                    MaximumOperatingAltitude: ""
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                HeliModel: {required},
                SeatNumber: {required},
                FlightNumber: {required},
                HeliAge: {required},
                MaximumTakeoffweight: {required},
                MaximumOperatingAltitude: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        HeliModel: this.editedData.HeliModel,
                        FlightNumber: this.editedData.FlightNumber,
                        SeatNumber: parseInt(this.editedData.SeatNumber),
                        HeliAge: parseInt(this.editedData.HeliAge),
                        MaximumTakeoffweight: parseInt(this.editedData.MaximumTakeoffweight),
                        MaximumOperatingAltitude: parseInt(this.editedData.MaximumOperatingAltitude)
                    };
                    axios.post("HeliCopter/InsertIntoHeliCopterDetailAsync", param).then(response => {
                        if (response.status == 200) {
                            this.$v.$reset();
                            this.servicesData.data = response.data
                            this.servicesData.message = "Details added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.HeliModel = "";
                this.editedData.SeatNumber = "";
            }
        }
    };
</script>

<style scoped>
</style>